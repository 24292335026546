<template>
    <div class="form-group input-group">
        <div v-if="prepend" class="input-group-prepend w-25">
            <span class="input-group-text w-100">{{prepend}}</span>
        </div>

        <input v-if="type=='checkbox'"
            ref="input"
            class="form-control"
            :type="type"
            :checked="value"
            :value="value"

            :true-value="true"
            :false-value="false"

            @input="input"
            :id="id"

            style="height: 25px; text-align: left; border: solid 1px black; max-width: 61px; margin-top: 7px;"
        />
        <select v-else-if="type === 'select'"
            ref="input"
            class="form-control"
            :value="value"
            @input="input"
            :id="id">
            <option
                v-for="name in Object.keys(options)"
                :value="name">
                {{ options[name] }}
            </option>
        </select>

        <textarea v-else-if="type === 'textarea'"
            ref="input"
            class="form-control"
            :value="value"
            @input="input"
            :id="id" />

        <input v-else
            ref="input"
            class="form-control"
            :type="type"
            :autocomplete="autocomplete||type"
            :placeholder="placeholder"
            :value="value"
            @input="input"
            @keydown="keydown"
            :id="id"
            :disabled="!!disabled"
            :min="String(min)"
            :max="String(max)"
            :step="String(step)"
            style="height:39px"
        />
        <div class="input-group-append" v-if="action">
            <button class="btn btn-outline-secondary" @click="onActionPress" type="button">{{action}}</button>
        </div>

        <span v-if="type==='range'" style="line-height:39px; width: 50px; text-align: right;">{{ value }}</span>
    </div>
</template>

<script>
	import {Component, Vue, Prop, Provide} from 'vue-property-decorator';

	@Component({
		components: {
		},
	})
	export default class FormInput extends Vue {
        @Prop({ type: String}) type;
        @Prop({ type: String}) action;
        @Prop({ type: String}) autocomplete;
        @Prop({ type: String}) placeholder;
        @Prop({ type: [String, Boolean, Number]}) value;
        @Prop({ type: String}) id;
        @Prop({ type: String}) prepend;
        @Prop({ type: Object }) options;
        @Prop({ type: Boolean }) disabled;
        @Prop({ type: Number }) min;
        @Prop({ type: Number }) max;
        @Prop({ type: Number }) step;

		focus() {
    		this.$refs.input.focus();
		}

        keydown(event) {
            if(this.action && event.which === 13) {
                this.$emit('onAction');
            }
        }

        onActionPress() {
            this.$emit('onAction');
        }

		input() {
            let value = this.$refs.input.value;
            if(this.type === 'checkbox') value = !!this.$refs.input.checked;
            if(this.type === 'range') value = Number(value);
    		this.$emit('input', value);
		}
	}
</script>

<style type="css">
input[type='range'] {
    margin-left: 20px;
}
</style>