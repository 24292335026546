import { AssertLogin } from '../../util/decorators';
import AdminAPI from '../../api';
import { importer } from './importer/store';
import { users } from './users/store';
import { scans } from './scans/store';
import { vr } from './vr/store';

export const api = {
	namespaced: true,

	state: {
		sessionId: null,
		user: null,
		connected: false,
		connecting: true,
	},

	getters: {
		isLoggedIn(state) {
			return Boolean(state.sessionId);
		},
		user(state) {
    		return state.user;
		},
		connected(state) {
    		return state.connected;
		},
		connecting(state) {
			return state.connecting;
		}
	},

	mutations: {
		_setSessionId(state, sessionId) {
			if (sessionId) {
				state.sessionId = sessionId;
			} else {
				state.sessionId = null;
			}
		},
		_setUser(state, user){
			state.user = user;
		},
		_clearAll(state){
			state.sessionId = null;
			state.user = null;
		},
		_setConnected(state, connected) {
    		state.connected = !!connected;
		},
		_setConnecting(state, connecting) {
    		state.connecting = !!connecting;
		}
	},

	actions: {
		async authenticate({ commit }, { username, password }) {
			const {sessionId, user} = await AdminAPI.fire('auth/login', {username, password});
            commit('_setSessionId', sessionId);
            commit('_setUser', user);
		},
		async _login({ commit, dispatch }, sessionId ) {
    		try {
			    let user = await AdminAPI.fire('auth/setSessionId', sessionId);
                commit('_setUser', user);
			} catch(err) {
    			commit('_setSessionId', null);
			}
		},
		logout({ commit, dispatch }){
			commit('_clearAll');
			Object.keys(api.modules).forEach(module => commit(`${module}/_clearAll`));
            return AdminAPI.fire('auth/logout');
		},
		@AssertLogin()
		async getUser({ commit }) {
    		let user = await AdminAPI.fire('auth/getUser');
            commit('_setUser', user);
		},
		_setUser({commit}, user) {
    		commit('_setUser', user);
		}
	},

	modules: {
		importer,
		users,
		scans,
		vr,
	},

	plugins: [
		(store) => {
			if(AdminAPI.connected) store.commit('api/_setConnected', true);
			store.commit('api/_setConnecting', AdminAPI.connecting);

			AdminAPI.on('action', (action, ...data) => store.dispatch('api/'+action, ...data));
			AdminAPI.on('disconnected', () => store.commit('api/_setConnected', false));
			AdminAPI.on('connected', () => {
				store.commit('api/_setConnecting', AdminAPI.connecting);
    			store.commit('api/_setConnected', true);
    			store.dispatch('api/_login', store.state.api.sessionId);
            });

			const { sessionId } = store.state.api;
			if (sessionId) {
				store.dispatch('api/_login', sessionId );
			}
		},
	],
};
