import { AssertLogin } from '../../../util/decorators';
import AdminAPI from '../../../api';

export const importer = {
	namespaced: true,

	state: {
    	queue: []
	},

	getters: {
    	list(state) {
        	return state.queue;
    	},
	},

	mutations: {
    	_setQueue(state, queue) {
        	state.queue = queue;
    	},
		_clearAll(state){
			state.queue = [];
		},
	},

	actions: {
        @AssertLogin()
    	refresh(context) {
        	return AdminAPI.fire('importer/refreshQueue');
    	},
    	
        @AssertLogin()
    	importModel(context, {modelId, includeFiles, title, slug, password}) {
        	return AdminAPI.fire('importer/importModel', {modelId, includeFiles, title, slug, password});
    	},

        @AssertLogin()
    	prefetchModel(context, {modelId, password}) {
        	return AdminAPI.fire('importer/prefetchModel', {modelId, password});
    	},

        @AssertLogin()
    	restoreScans(context) {
        	return AdminAPI.fire('importer/restoreScans');
    	},
    	
    	_importQueueChanged({ commit }, queue) {
			commit('_setQueue', queue);
    	}
	},
};
