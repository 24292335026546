<template>
    <div class="modal fade" tabindex="-1" role="dialog" ref="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="model-title">{{ title }}</h4>

                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                </div>

                <div class="modal-body">
                    <slot />
                </div>

                <div class="modal-footer">
                    <slot name="footer" />

                    <Button secondary data-dismiss="modal" icon="times" title="Close" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import Button from '../Button';

    @Component({
        components: {
            Button
        }
    })
    export default class ModalDialog extends Vue {
        @Prop(String) title;
        @Prop(Boolean) visible;

        @Watch('visible')
        onVisibleChanged(visible) {
            if (visible) {
                $(this.$refs.dialog).modal('show');
            } else {
                $(this.$refs.dialog).modal('hide');
            }
        }

        _onModalHide() {
            this.$emit('hidden');
        }

        mounted() {
            this.$nextTick(() => {
                $(this.$refs.dialog).on('hide.bs.modal', this._onModalHide.bind(this));
            });
        }

        beforeDestroy() {
            $(this.$refs.dialog).removeEventListener('hide.bs.modal', this._onModalHide.bind(this));
        }
    }
</script>

<style type="css">
.modal-body {
    max-height: 70vh;
    overflow-y: auto;
}
</style>