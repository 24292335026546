<template>
    <SidebarLayout>
        <Card title="VR Headset">
            <Button slot="header-widget" title="Back" icon="caret-left" @onPress="back" small/>

            <Form v-if="headset" @submit="saveHeadset" :error="error">
                <FormInput
                    type="text"
                    autocomplete="text"
                    prepend="Name"
                    v-model="headset.name"
                />
                <FormInput
                    type="text"
                    autocomplete="text"
                    prepend="Serial"
                    v-model="headset.serial"
                />
                <FormInput
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Enabled"
                    v-model="headset.enabled"
                />
                <FormInput
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="3D Objects"
                    v-model="headset.stereoscopicRenderingEnabled"
                />
                <FormInput
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Motion"
                    v-model="headset.motionEnabled"
                />
                <hr />
                <FormInputDualListSelector
                    :source="scans"
                    searchField="modelName"
                    :columns="scanColumns"
                    v-model="headset.scans">
                    <Avatar :src="row.thumb" slot="icon" slot-scope="{row}" width="40" height="40" class="media-left" />
                </FormInputDualListSelector>
            </Form>

            <Button class="float-right" slot="footer" title="Save" icon="save" primary @onPress="saveHeadset"/>
        </Card>
    </SidebarLayout>
</template>

<script>
	import {Component, Vue, Prop} from 'vue-property-decorator';
	import SidebarLayout from '../../layouts/Sidebar';
    import Avatar from '../../components/Avatar';
	import Card from '../../components/Card';
	import Button from '../../components/Button';
	import Form from '../../components/Form';
	import FormInput from '../../components/FormInput';
    import FormInputDualListSelector from '../../components/FormInputDualListSelector';
	import {Getter, Action, Mutation} from 'vuex-class';

    const SCAN_COLUMNS = [
        {
            name: 'Name',
            id: 'modelName'
        }
    ];

	@Component({
		components: {
            Avatar,
			SidebarLayout,
			Card,
			Button,
			FormInput,
            FormInputDualListSelector,
			Form
		},
	})
	export default class ViewEditVRHeadset extends Vue {
    	@Action('api/vr/headsets/editHeadset') editHeadset;
    	@Action('api/vr/headsets/getHeadset') getHeadset;

        @Getter('api/scans/list') scans;
        @Action('api/scans/refresh') refreshScans;

        headset = null;
        error = '';

        scanColumns = SCAN_COLUMNS;

        async beforeMount() {
            if(this.$route.params.id) {
                this.headset = await this.getHeadset(this.$route.params.id);
            } else {
                this.headset = {};
            }

            this.refreshScans();
        }

        back() {
            this.$router.back();
        }

        async saveHeadset() {
            this.error = '';

            try {
                console.log('EditVRHeadset#saveHeadset', this.headset);
                await this.editHeadset(this.headset);
                this.$router.push({name:'VRHeadsets'});
            } catch(err) {
                this.error = err.message;
            }

        }
	}
</script>
