import Vue from 'vue';
import * as Vuex from 'vuex';
import VueResource from 'vue-resource';

import markdown from 'markdown';

import App from './App.vue';
import router from './router';
import { store } from './store/store';
import './registerServiceWorker';


Vue.use(Vuex);
Vue.use(VueResource);

Vue.config.productionTip = false;

Vue.filter('markdown', (value) => { markdown.toHTML(value); });

// router.beforeEach((to, from, next) => {
//   // this route requires auth, check if logged in
//   // if not, redirect to login page.
//   if (to.name !== 'login' && !store.getters['api/isLoggedIn']) {
//     next({
//       path: '/login',
//       query: { redirect: to.fullPath },
//     });
//   }
//   next(); // make sure to always call next()!
// });

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
