var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}}),_c('TableListView',{attrs:{"columns":_vm.columns,"rows":_vm.filteredSource},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var row = ref.row;
return (_vm.$scopedSlots.icon)?_c('span',{},[_vm._t("icon",null,{"row":row})],2):_vm._e()}},{key:"actions",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('Button',{attrs:{"primary":"","small":"","icon":"plus"},on:{"onPress":function($event){return _vm.addItem(row)}}})],1)}}],null,true)}),(_vm.filteredSource.length === 0 && _vm.searchQuery.trim().length )?_c('p',{staticClass:"text-muted"},[_vm._v(" No search results. ")]):(_vm.filteredSource.length === 0)?_c('p',{staticClass:"text-muted"},[_vm._v(" No items were found. ")]):_vm._e(),_c('hr'),(_vm.value.length)?_c('DraggableTableListView',{attrs:{"columns":_vm.columns},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var row = ref.row;
return (_vm.$scopedSlots.icon)?_c('span',{},[_vm._t("icon",null,{"row":row})],2):_vm._e()}},{key:"actions",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('Button',{attrs:{"danger":"","small":"","icon":"minus"},on:{"onPress":function($event){return _vm.removeItem(row)}}})],1)}}],null,true),model:{value:(_vm.rows),callback:function ($$v) {_vm.rows=$$v},expression:"rows"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }