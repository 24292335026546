<template>
    <table class="table">
        <thead>
            <tr>
                <th v-if="$scopedSlots.icon" style="width: 40px;"><slot name="icon-header"/></th>
                <th v-for="(col, j) in columns" v-bind:class="{'d-none': j, 'd-md-table-cell': j}">{{col.name}}</th>
                <th v-if="$scopedSlots.actions" class="text-right"><slot name="actions-header"/></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in rows" class="align-middle">
                <td class="align-middle" v-if="$scopedSlots.icon"><slot name="icon" :row="row"/></td>
                <td class="align-middle tlv-cell" v-for="(col, j) in columns" v-bind:class="{'d-none': j, 'd-md-table-cell': j}">{{ (col.filter ? col.filter(row[col.id]) : row[col.id]) }}</td>
                <td class="align-middle text-right" v-if="$scopedSlots.actions"><slot name="actions" :row="row"/></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
	import { Component, Vue, Prop } from 'vue-property-decorator';

	@Component({
		components: {
		},
	})
	export default class TableListView extends Vue {
    	@Prop(Array) columns;
    	@Prop(Array) rows;
	}
</script>
<style type="css">
.tlv-cell {
    word-break: break-all;
    max-width: 435px;
}
</style>