import io from 'socket.io-client';
import Emitter from 'component-emitter';

class AdminAPI {
    
    constructor(opts) {
        this._io = io(opts);
        this._io.on('connect', () => this._onConnected());
        this._io.on('disconnect', () => this._onDisconnected());
        this._io.on('connect_error', (err) => this._onConnectError(err));
        this._io.on('action', (...data) => this._onAction(...data));
        this.connecting = true;
    }
    
    _onConnected() {
        console.log('connected');
        this.connected = true;
        this.connecting = false;
        this.emit('connected');
    }
        
    _onDisconnected() {
        console.log('disconnected');
        this.connected = false;
        this.connecting = false;
        this.emit('disconnected');
    }
    
    _onConnectError(err) {
        console.log('connect error', err);
        this.connected = false;
        this.connecting = false;
        this.emit('disconnected');
    }
    
    _onAction(...data) {
        this.emit('action', ...data);
    }
    
    async fire(event, data) {
        return new Promise((resolve, reject) => {
            this._io.emit(event, data, (err, res) => {
                if(err) return reject(new Error(err));
                resolve(res);
            })
        });
    }
};

const isLocal = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");

const api = new AdminAPI(isLocal ? 'http://localhost:8000' : undefined);
Emitter(api);

export default api;