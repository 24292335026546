<template>
    <div>
        <SidebarLayout ref="sidebar">
            <Card v-if="scan" :title="scan && scan.modelName">
                <Button slot="header-widget" title="Back" icon="caret-left" @onPress="back" small/>

                <Form>
                    <FormInput 
                        type="text"
                        autocomplete="text"
                        prepend="Name"
                        v-model="scan.modelName"
                        :disabled="true" 
                    />
                    
                    <FormInput 
                        type="text"
                        autocomplete="text"
                        prepend="URL"
                        v-model="scan.link" 
                        :disabled="true"
                    />
                    
                    <FormInput 
                        type="text"
                        autocomplete="text"
                        prepend="ID"
                        v-model="scan.modelId" 
                        :disabled="true"
                        :action="scan.outdatedPlayer ? undefined : 'Import'"
                        @onAction="importScan"
                    />
                </Form>

                <Button title="Remove Files" icon="trash" danger small @onPress="deleteScan()"/>

                <div style="float: right">
                    <Button v-if="!scan.outdatedFiles && !scan.outdatedPlayer" title="Reset Cache" icon="sync" danger small @onPress="resetCache()"/>
                    <Button v-if="scan.outdatedPlayer" title="Update Player" icon="sync" small :primary="true" @onPress="updatePlayer()"/>
                    <Button title="View" icon="external-link-alt" small @onPress="openModel()"/>
                </div>

                <p v-if="scan.outdatedFiles" style="color: red; font-weight: bold;"><i class="fas fa-exclamation-triangle"></i> Outdated Files</p>
                <p v-if="scan.outdatedPlayer" style="color: orange; font-weight: bold;"><i class="fas fa-exclamation-triangle"></i> Outdated Player</p>
                
            </Card>

            <Card title="AV Screens">
                <span slot="header-widget" >
                    <Button :disabled="!!uploading" title="Publish" icon="upload" small @onPress="publishAVScreens()"/>
                    <Button title="Add" icon="plus" @onPress="addLocation('video')" small/>
                </span>
                <TableListView :columns="avColumns" :rows="scan && scan.videos">
                    <span slot="actions" slot-scope="{row}" class="text-right model-actions">
                        <Button icon="edit" primary small @onPress="openAVScreen(row)" />
                        <Button icon="trash" small danger @onPress="deleteAVScreen(row)"/>
                    </span>
                </TableListView>
                <Button :disabled="!!uploading" title="Publish" icon="upload" small style="float: right;" @onPress="publishAVScreens()"/>
            </Card>

            <Card title="Tags">
                <span slot="header-widget" >
                    <Button title="Copy" icon="copy" small @onPress="toggleCopyTagsModal()"/>
                    <Button :disabled="!!uploading" title="Publish" icon="upload" small @onPress="publishTags()"/>
                    <Button title="Add" icon="plus" @onPress="addLocation('tag')" small/>
                </span>
                <TableListView :columns="tagColumns" :rows="scan && scan.tags">
                    <Avatar slot="icon" v-if="row.media.type === 'photo'" slot-scope="{row}" :src="row.media.src" width="40" height="40" />
                    <span slot="actions" slot-scope="{row}" class="text-right model-actions">
                        <Button icon="edit" primary small @onPress="openTag(row)" />
                        <Button icon="trash" small danger @onPress="deleteTag(row)"/>
                    </span>
                </TableListView>
                <Button :disabled="!!uploading" title="Publish" icon="upload" small style="float: right;" @onPress="publishTags()"/>
            </Card>

            <Card title="Users">
                <span slot="header-widget" >
                    <Button :disabled="!!uploading" title="Sync Users" icon="upload" small @onPress="publishUsers()"/>
                    <Button title="Add" icon="plus" @onPress="togglePutUserModal()" small/>
                </span>
                <TableListView :columns="userColumns" :rows="scan && scan.passwords">
                    <span slot="actions" slot-scope="{row}" class="text-right model-actions">
                        <Button icon="edit" primary small @onPress="togglePutUserModal(row)" />
                        <Button icon="trash" small danger @onPress="deleteUser(row)" />
                    </span>
                </TableListView>
                 <Button :disabled="!!uploading" title="Sync Users" icon="upload" small style="float: right;" @onPress="publishUsers()"/>
            </Card> 
<!-- 
            <Card title="Locations">
                <TableListView :columns="locationColumns" :rows="scan && scan.locations">
                    <Avatar slot="icon" slot-scope="{row}" :src="scan.scanBaseUrl+row.thumbnail" />
                    <span slot="actions" slot-scope="{row}" class="text-right model-actions">
                        <Button icon="arrows-alt" primary small @onPress="openLocation(row)" />
                    </span>
                </TableListView>
            </Card> -->
        </SidebarLayout>

        <ModalDialog title="Location Selector" :visible="openLocationSelector !== null" @hidden="hideLocationSelectorModal()">
            <iframe width="100%" height="500px" :src="`${scan.link}#videoEditor=1&dh=0&play=true&help=0&type=${openLocationSelector}&screen=${movingScreen && movingScreen.id}`" v-if="openLocationSelector"></iframe>
            <p v-if="openLocationSelector === 'video'">
                rotate: Y, scale: G, translate: T
            </p>
            <Button :disabled="!openedAVScreen && !openedTag" class="float-right" slot="footer" title="Save" icon="save" primary @onPress="pickLocation()" />
        </ModalDialog>

        <ModalDialog title="AV Screen" :visible="!openLocationSelector && openedAVScreen !== null" @hidden="hideVideoModal()">
            <Form v-if="openedAVScreen" @submit="updateAVScreen()">

                <video v-if="openedAVScreen.url && isVideo" width="100%" height="300" controls>
                    <source :src="scan.scanBaseUrl+openedAVScreen.url" type="video/mp4">
                    Your browser does not support the video tag.
                </video>

                <audio v-if="openedAVScreen.url && isAudio" style="width:100%" controls>
                    <source :src="scan.scanBaseUrl+openedAVScreen.url" type="audio/mpeg">
                    Your browser does not support the audio tag.
                </audio>

                <img v-if="openedAVScreen.url && isImage" :src="scan.scanBaseUrl+openedAVScreen.url" height="100" />

                <p></p>

                <FormInput 
                    type="text"
                    autocomplete="text"
                    prepend="Name"
                    v-model="openedAVScreen.name"
                />

                <FormInput
                    type="select"
                    prepend="Type"
                    :options="avScreenTypes"
                    v-model="openedAVScreen.type"
                />

                <FormInput
                    v-if="isVideo"
                    type="file"
                    prepend="H.264 MP4 Video"
                    id="upload"
                    value=""
                />

                <FormInput
                    v-if="isAudio"
                    type="file"
                    prepend="MP3 file"
                    id="upload"
                    value=""
                />

                <FormInput
                    v-if="isImage"
                    type="file"
                    prepend="PNG File"
                    id="upload"
                    value=""
                />

                <FormInput
                    v-if="isVideo"
                    type="color"
                    autocomplete="checkbox"
                    prepend="Chroma Key"
                    v-model="openedAVScreen.chromaKeyColor"
                />

                <FormInput
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Enabled"
                    v-model="openedAVScreen.enabled"
                />

                <FormInput
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Enabled (VR)"
                    v-model="openedAVScreen.enabled_vr"
                />

                <FormInput
                    v-if="isVideo || isAudio"
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Play Once"
                    v-model="openedAVScreen.singlePlay"
                />

                <FormInput
                    v-if="openedAVScreen.singlePlay"
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Remove when ended"
                    v-model="openedAVScreen.removeAfterPlay"
                />

                <FormInput
                    v-if="isVideo || isImage"
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Always Face Camera"
                    v-model="openedAVScreen.lookAtCamera"
                />

                <FormInput
                    v-if="isVideo || isAudio"
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Only on same floor"
                    v-model="openedAVScreen.floorAware"
                />

                <FormInput
                    v-if="isVideo || isAudio"
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Auto-Pause"
                    v-model="openedAVScreen.autoPause"
                />

                <FormInput
                    v-if="(isVideo && openedAVScreen.autoPause) || isAudio"
                    type="range"
                    prepend="Activation Distance"
                    :min="0"
                    :max="20"
                    :step="0.5"
                    v-model="openedAVScreen.activationDistance"
                />


                <FormInput
                    v-if="(isVideo || isImage)"
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Auto-Hide"
                    v-model="openedAVScreen.autoHide"
                />

                <FormInput
                    v-if="((isVideo || isImage) && openedAVScreen.autoHide)"
                    type="range"
                    prepend="Hide Distance (m)"
                    :min="0"
                    :max="20"
                    :step="0.5"
                    v-model="openedAVScreen.hideDistance"
                />

                <FormInput
                    v-if="isVideo || isAudio"
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Resume at beginning"
                    v-model="openedAVScreen.autoRewind"
                />

                <FormInput
                    v-if="isVideo || isAudio"
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Play Exclusively"
                    v-model="openedAVScreen.exclusive"
                />

                <FormInput
                    v-if="isVideo"
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Mute"
                    v-model="openedAVScreen.muted"
                />


                <FormInput
                    type="range"
                    prepend="Distance (meters)"
                    :min="-10"
                    :max="10"
                    :step="0.01"
                    v-model="openedAVScreen.distance"
                />


                <FormInput
                    v-if="isVideo || isAudio"
                    type="range"
                    prepend="Volume"
                    :min="0.01"
                    :max="1"
                    :step="0.01"
                    v-model="openedAVScreen.volume"
                />

            </Form>

            <Button @onPress="updateAVLocation()" slot="footer" warning icon="street-view" title="Change Location" style="margin-right:auto;"/>

            <Button :disabled="!!uploading" class="float-right" slot="footer" title="Save" icon="save" primary @onPress="updateAVScreen()" />
        </ModalDialog>

        <ModalDialog title="Location" :visible="openedLocation !== null" @hidden="hideLocationModal()">
            <img class="img-fluid" :src="scan.scanBaseUrl+openedLocation.image" v-if="openedLocation">

            <Form v-if="openedLocation" @submit="updateLocation()">
                <FormInput
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Enabled"
                    v-model="openedLocation.enabled"
                    />

                <FormInput
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Enabled (VR)"
                    v-model="openedLocation.vr_enabled"
                    />


                <FormInput
                    type="checkbox"
                    autocomplete="checkbox"
                    prepend="Indexed (VR)"
                    v-model="openedLocation.indexed"
                    />
            </Form>

            <Button class="float-right" slot="footer" title="Save" icon="save" primary @onPress="updateLocation()" />
        </ModalDialog>

        <ModalDialog title="Tag" :visible="!openLocationSelector && openedTag !== null" @hidden="hideTagModal()">
            <Form v-if="openedTag" @submit="updateTag()">
                <FormInput
                    type="text"
                    prepend="Label"
                    v-model="openedTag.label"
                    />

                <FormInput
                    type="select"
                    prepend="Icon"
                    :options="tagIcons"
                    v-model="openedTag.iconType" />

                <FormInput
                    type="select"
                    prepend="Language"
                    :options="tagLanguages"
                    v-model="openedTag.language" />

                <FormInput
                    type="color"
                    prepend="Color"
                    v-model="openedTag.color"
                />

                <FormInput
                    type="select"
                    prepend="Type"
                    :options="tagMediaTypes"
                    v-model="openedTag.media.type" />

                <FormInput
                    v-if="openedTag.media.type === 'photo'"
                    type="text"
                    prepend="Photo URL"
                    v-model="openedTag.media.src" />

                <FormInput
                    type="range"
                    prepend="Size (meters)"
                    :min="0"
                    :max="5"
                    :step="0.01"
                    v-model="openedTag.stem.size"
                />

                <FormInput
                    v-if="openedTag.media.type === 'video'"
                    type="text"
                    prepend="YouTube/Vimeo URL"
                    v-model="openedTag.media.src" />

                <FormInput
                    type="textarea"
                    prepend="Text"
                    v-model="openedTag.description" />

                <h5>Preview</h5>

                <span v-html="renderMarkdown(openedTag.description)" />
            </Form>

            <Button @onPress="updateTagLocation()" slot="footer" warning icon="street-view" title="Change Location" style="margin-right:auto;"/>

            <Button @onPress="updateTag()" slot="footer" primary icon="save" title="Save" />
        </ModalDialog>

        <ModalDialog title="Copy Tags" :visible="openCopyTags" @hidden="toggleCopyTagsModal()">
            <Form @submit="copyTags()">
                <FormInput
                    type="select"
                    prepend="Copy From"
                    :options="scanDropdown"
                    v-model="copyTagsSource"
                />
            </Form>
            <Button @onPress="copyTags()" slot="footer" primary icon="save" title="Copy" />
        </ModalDialog>


        <ModalDialog title="User" :visible="!!currentUser" @hidden="currentUser = null">
            <Form @submit="putUser()" v-if="!!currentUser">
                <FormInput
                    type="text"
                    prepend="Username"
                    v-model="currentUser.username"
                    :disabled="!currentUser.isNew"
                />
                <FormInput
                    type="text"
                    prepend="Password"
                    v-model="currentUser.password"
                />
            </Form>
            <Button @onPress="putUser()" slot="footer" primary icon="save" :disabled="!currentUser || !currentUser.username || !currentUser.password" title="Save" />
        </ModalDialog>

        <ModalDialog title="Error" :visible="!!errorMsg" @hidden="clearError()">
            {{errorMsg}}
        </ModalDialog>
    </div>
</template>

<script>
	import {Component, Vue} from 'vue-property-decorator';
	import SidebarLayout from '../../layouts/Sidebar';
	import Card from '../../components/Card';
	import Button from '../../components/Button';
	import Form from '../../components/Form';
	import FormInput from '../../components/FormInput';
	import Avatar from '../../components/Avatar';
    import TableListView from '../../components/TableListView';
    import ModalDialog from '../../components/ModalDialog';
	import {Getter, Action} from 'vuex-class';
    import {markdown} from 'markdown';
    import _ from 'lodash';

    const AV_COLUMNS = [
        {
            name: 'Screen Name',
            id: 'name',
        }
    ];

    const TAG_COLUMNS = [
        {
            name: 'Label',
            id: 'label'
        },
        {
            name: 'Description',
            id: 'description'
        }
    ];

    const LOCATION_COLUMNS = [
        {
            name: 'Enabled',
            id: 'enabled',
            filter: (val) => val ? 'Yes' : 'No'
        },
        {
            name: 'Enabled (VR)',
            id: 'vr_enabled',
            filter: (val) => val ? 'Yes' : 'No'
        },
        {
            name: 'Indexed (VR)',
            id: 'indexed',
            filter: (val) => val ? 'Yes' : 'No'
        }
    ];

    const USER_COLUMNS = [
        {
            name: 'Username',
            id: 'username',
        },
        {
            name: 'Password',
            id: 'password',
        }
    ];

    const TAG_MEDIA_TYPES = {
        '': 'No media',
        'photo': 'Photo',
        'video': 'Video',
    };

    const TAG_ICONS = {
        '': 'Empty',
        'info': 'Info',
        'videocam': 'Videocam',
        'picturecam': 'Picture Camera',
        'brush': 'Brush',
        'panorama': 'Panorama',
    };

    const TAG_LANGUAGES = {
        '': 'All',
        'nl': 'NL',
        'en': 'EN',
        'de': 'DE',
        'fr': 'FR',
        'cn': 'CN',
        'es': 'ES',
        'jp': 'JP',
        'ru': 'RU',
    };

    const AV_SCREEN_TYPES = {
        'video': 'Video',
        'audio': 'Audio',
        'image': 'Image',
    };

	@Component({
		components: {
			SidebarLayout,
			Card,
			Button,
			FormInput,
			Form,
			Avatar,
            TableListView,
            ModalDialog,
		},
	})
	export default class ViewShowScan extends Vue {
    	@Getter('api/scans/list') allScans;
    	@Getter('api/scans/scan') scan;
    	@Action('api/scans/refresh') refreshScans;
    	@Action('api/scans/getScan') getScan;
    	@Action('api/scans/deleteScan') _deleteScan;
        @Action('api/scans/resetCache') _resetCache;
    	@Action('api/importer/importModel') importModel;
        @Action('api/scans/locations/editLocation') editLocation;
        @Action('api/scans/tags/editTag') editTag;
        @Action('api/scans/tags/addTag') addTag;
        @Action('api/scans/tags/deleteTag') _deleteTag;
        @Action('api/scans/tags/publishTags') _publishTags;
        @Action('api/scans/tags/copyTags') copyTagsFromScan;
        @Action('api/scans/videos/editVideo') editVideo;
        @Action('api/scans/videos/deleteVideo') deleteVideo;
        @Action('api/scans/videos/publishVideos') publishVideos;
        @Action('api/scans/videos/createUploadUrl') createVideoUploadUrl;
        @Action('api/scans/putUser') _putUser;
        @Action('api/scans/removeUser') _removeUser;
        @Action('api/scans/publishUsers') _publishUsers;

        avColumns = AV_COLUMNS;
        tagColumns = TAG_COLUMNS;
        userColumns = USER_COLUMNS;

        locationColumns = LOCATION_COLUMNS;

        tagMediaTypes = TAG_MEDIA_TYPES;
        tagIcons = TAG_ICONS;
        tagLanguages = TAG_LANGUAGES;
        avScreenTypes = AV_SCREEN_TYPES;

        openedAVScreen = null;

        openedLocation = null;

        openLocationSelector = null;

        openCopyTags = false;
        copyTagsSource = null;

        openedTag = null;

        movingTag = null;
        movingScreen = null;

        error = '';

        uploading = false;

        currentUser = null;
        
        errorMsg = null;
        clearError() {
            this.errorMsg = null;
        }
        showError(e) {
            console.error(e);
            this.errorMsg = e.message;
        }
        

        async beforeMount() {
            await this.getScan(this.$route.params.id);
            await this.refreshScans();
            this.copyTagsSource = this.allScans[0] ? this.allScans[0].id : 0;
        }

        get scanDropdown() {
            return this.allScans.reduce((r,s) => Object.assign(r, {[s.id]: s.modelName}), {5:'a', 6: 'b'});
        }

        get isVideo() {
            return this.openedAVScreen && this.openedAVScreen.type === 'video';
        }

        get isImage() {
            return this.openedAVScreen && this.openedAVScreen.type === 'image';
        }

        get isAudio() {
            return this.openedAVScreen && this.openedAVScreen.type === 'audio';
        }

        renderMarkdown(md) {
            return markdown.toHTML(md);
        }

        openLocation(location) {
            this.openedLocation = _.cloneDeep(location);
        }

        openTag(tag) {
            this.openedTag = _.cloneDeep(tag);
        }

        back() {
            this.$router.back();
        }

        hideLocationModal() {
            this.openedLocation = null;
        }

        hideTagModal() {
            this.openedTag = null;
        }

        onMessageReceived(event) {
            let data;
            try {
                data = JSON.parse(event.data);
            } catch(e) {

            }
            if(data && data.event)
            switch(data.event) {
                case 'foundVideoScreen': {
                    if (this.openLocationSelector !== 'video') return;
                    this.addAVScreen(data.screen);
                }
                break;
                case 'foundTag': {
                    if (this.openLocationSelector !== 'tag') return;
                    this.foundTag(data.tag);
                }
                break;
                default:
                break;
            }
        }

        addAVScreen(screen) {
            if(this.movingScreen) {
                Object.assign(this.movingScreen, {
                    position: screen.position,
                    quaternion: screen.quaternion,
                    width: screen.width,
                    height: screen.height,
                    distance: screen.distance,
                    up: screen.up,
                });
                this.openedAVScreen = this.movingScreen;
            } else {
                this.openAVScreen(screen);
            }
            
        }

        openAVScreen(screen) {
            screen.chromaKeyColor = screen.chromaKeyColor || '#000000';
            screen.name = screen.name || 'New Screen '+(this.scan.videos.length+1);
            this.openedAVScreen = screen;
        }

        foundTag(tag) {
            if (this.movingTag) {
                this.movingTag.position = tag.position;
                this.movingTag.normal = tag.normal;
                this.movingTag.stem.direction = tag.stem.direction;
                this.movingTag.stem.size = 0.25;
                this.openedTag = this.movingTag;
                console.log(this.openedTag, tag);
                return;
            }
            tag.media = tag.media || {};
            tag.label = 'Tag '+this.scan.tags.length;
            tag.scanId = this.$route.params.id;
            tag.new = true;
            tag.enabled = true;
            tag.enabled_vr = true;
            this.openedTag = tag;
        }

        updateTagLocation() {
            this.movingTag = this.openedTag;
            this.addLocation('tag');
        }

        updateAVLocation() {
            this.movingScreen = this.openedAVScreen;
            this.addLocation('video');
        }

        addLocation(type) {
            this.openLocationSelector = type;

            this._onMessageReceived = this.onMessageReceived.bind(this);
            window.addEventListener("message", this._onMessageReceived, false);
        }

        hideLocationSelectorModal() {
            this.openLocationSelector = null;
            window.removeEventListener("message", this._onMessageReceived);
        }

        hideVideoModal() {
            this.openedAVScreen = null;
        }

        pickLocation() {
            this.hideLocationSelectorModal();
        }

        openModel(link) {
            window.open(this.scan.link, '_blank');
        }

        async resetCache() {
            this.uploading = true;
            await this._resetCache(this.$route.params.id);
            this.uploading = false;
        }

        async publishAVScreens() {
            this.uploading = true;
            await this.publishVideos(this.$route.params.id);
            this.uploading = false;
        }

        async publishTags() {
            this.uploading = true;
            await this._publishTags(this.$route.params.id);
            this.uploading = false;
        }

        async deleteTag(tag) {
            if(confirm('Are you sure you want to delete this Tag?') !== true)
                return;
            await this._deleteTag(tag.id);
            await this.getScan(this.$route.params.id);
        }

        async deleteAVScreen(screen) {
            if(confirm('Are you sure you want to delete this Video?') !== true)
                return;
            await this.deleteVideo(screen.id);
            await this.getScan(this.$route.params.id);
        }

        async updateAVScreen() {
            this.uploading = true;
            const video = await this.editVideo({video: this.openedAVScreen, scanId: this.$route.params.id});
            this.openedAVScreen.id = video.id;
            const fileUpload = document.getElementById('upload');
            if(fileUpload.files && fileUpload.files[0]) {
                const {url, fields, filename} = await this.createVideoUploadUrl(video.id);

                console.log(url, fields);

                var data = new FormData();
                for(const [key, value] of Object.entries(fields)) {
                    data.append(key, value);
                }

                data.append('file', fileUpload.files[0]);

                await fetch(url, {
                    method: 'POST',
                    mode: 'no-cors',
                    body: data
                });
                this.openedAVScreen.url = filename+'?v='+(new Date()).toISOString();
                await this.editVideo({video: this.openedAVScreen, scanId: this.$route.params.id});
            }

            this.openedAVScreen = null;
            this.movingScreen = null;

            await this.getScan(this.$route.params.id);
            this.uploading = false;
        }

        async updateTag() {
            this.openedTag.stem.direction = {
                x: this.openedTag.normal.x * this.openedTag.stem.size,
                y: this.openedTag.normal.y * this.openedTag.stem.size,
                z: this.openedTag.normal.z * this.openedTag.stem.size,
            };
            if(this.openedTag && this.openedTag.new) {
                await this.addTag(this.openedTag);
            } else {
                await this.editTag(this.openedTag);
            }

            this.openedTag = null;

            await this.getScan(this.$route.params.id);
        }

        async updateLocation() {
            await this.editLocation(this.openedLocation);

            this.openedLocation = null;

            await this.getScan(this.$route.params.id);
        }

        importScan() {
            this.$refs.sidebar.loadImportId(this.scan.modelId);
        }

        async updatePlayer() {
            await this.importModel({modelId: this.scan.modelId, includePlayer: true})
                .catch(e => this.showError(e));
            await this.getScan(this.$route.params.id);
            await this.refreshScans();
        }

        toggleCopyTagsModal() {
            this.openCopyTags = !this.openCopyTags;
        }

        togglePutUserModal(user) {
            if(this.currentUser) return this.currentUser = null;
            this.currentUser = {
                username: user && user.username || '',
                password: user && user.password || '',
                isNew: !user,
            };
        }

        async putUser() {
            const currentUser = this.currentUser;
            this.togglePutUserModal();
            await this._putUser({
                scanId: this.scan.id,
                username: currentUser.username,
                password: currentUser.password,
            });
            await this.getScan(this.$route.params.id);
        }

        async deleteUser(user) {
            if(confirm('Are you sure you want to delete '+user.username+'?') !== true)
                return;
            await this._removeUser({
                scanId: this.scan.id,
                username: user.username,
            });
            await this.getScan(this.$route.params.id);
        }

        async deleteScan() {
            if(confirm('Are you sure you want to fully delete this Scan?') !== true)
                return;
            await this._deleteScan(this.scan.id);
            this.back();
        }

        async copyTags() {
            this.openCopyTags = false;
            await this.copyTagsFromScan({destination: this.scan.id, source: this.copyTagsSource});
            await this.getScan(this.$route.params.id);
        }

        async publishUsers() {
            if(this.uploading) return;
            this.uploading = true;
            await this._publishUsers();
            this.uploading = false;
        }
	}
</script>

<style lang="css">
    .model-actions {
        width: 125px;
    }
</style>
