import { AssertLogin } from '../../../util/decorators';
import AdminAPI from '../../../api';
import { locations } from './locations/store';
import { tags } from './tags/store';
import { videos } from './videos/store';

export const scans = {
  namespaced: true,

  state: {
    scans: [],
    scan: undefined,
  },

  getters: {
    list(state) {
      return state.scans;
    },
    scan(state) {
      return state.scan;
    }
  },

  modules: {
    locations,
    tags,
    videos,
  },

  mutations: {
    _setScans(state, scans) {
      state.scans = scans;
    },
    _setScan(state, scan) {
      state.scan = scan;
    },
    _clearScan(state){
      state.scan = undefined;
    },
    _clearAll(state){
      state.scans = [];
    },
    _deleteScan(state, scanId) {
      state.scans = state.scans.filter(s => s.id !== scanId);
    }
  },

  actions: {
    @AssertLogin()
    async refresh({commit, state}) {
      const scans = await AdminAPI.fire('scans/getAll');
      commit('_setScans', scans);

      if(state.scan) {
        const beforeId = state.scan.id;
        const scan = await AdminAPI.fire('scans/getScan', beforeId);
        if(state.scan.id === beforeId) {
          commit('_setScan', scan);
        }
      }
    },

    @AssertLogin()
    async getScan({commit, state}, id) {
      if(state.scan && state.scan.id !== Number(id)) {
        commit('_setScan', undefined);
      }
      const scan = await AdminAPI.fire('scans/getScan', id);
      commit('_setScan', scan);
    },

    @AssertLogin()
    async resetCache({commit}, id) {
      return await AdminAPI.fire('scans/resetCache', id);
    },

    @AssertLogin()
    async putUser({commit}, {scanId, username, password}) {
      return await AdminAPI.fire('scans/putUser', {scanId, username, password});
    },

    @AssertLogin()
    async removeUser({commit}, {scanId, username}) {
      return await AdminAPI.fire('scans/removeUser', {scanId, username});
    },

    @AssertLogin()
    async publishUsers({commit}) {
      return await AdminAPI.fire('scans/publishUsers');
    },

    @AssertLogin()
    async deleteScan({commit}, id) {
      await AdminAPI.fire('scans/deleteScan', id);
      commit('_deleteScan', id);
    }
  },
};
