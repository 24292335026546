<template>
	<AuthLayout>
	    <div class="row pt-3">
    	    <div class="col-sm">
    		    <slot/>
    		</div>
    	    <div class="col-md-4">
    	        <Card title="Tasks" v-if="$slots.tasks">
    	            <slot name="tasks"/>
        		</Card>
        	    <Card title="Import Queue">

                    <Button slot="header-widget" title="Restore" icon="trash-restore" @onPress="restore" small :disabled="busy"/>
                    <div v-for="item in items" class="pb-4" v-bind:key="item.name||item.id">
                        {{item.name || item.id}}
                        <small class="form-text text-muted float-right">{{item.remaining | timeRemaining}}</small>
            		    <div class="progress">
                            <div 
                                class="progress-bar progress-bar-striped progress-bar-animated bg-success text-truncate"
                                role="progressbar" 
                                v-bind:style="{ width: item.progress + '%' }"
                            >{{item.progress}}%</div>
                        </div>
                    </div>
                    <small v-if="!items || !items.length">
                        Queue is empty
                    </small>
                    <template slot="footer">
                        <FormInput
                            type="text"
                            prepend="ID"
                            @input="onModelIdChange"
                            v-model="state.importId"/>
                        <FormInput
                            type="password"
                            autocomplete="new-password"
                            prepend="Pass"
                            action="Login"
                            @onAction="onLogin"
                            v-if="state.passwordRequired && !state.token"
                            v-model="state.password"/>
                        <FormInput
                            type="text"
                            prepend="Title"
                            v-if="!!this.state.title"
                            @input="onTitleChange"
                            v-model="state.title"/>
                        <FormInput
                            type="text"
                            prepend="Slug"
                            v-if="!!this.state.slug"
                            :disabled="!state.slugEditable"
                            v-model="state.slug"/>

                        <Button
                            class="float-left"
                            title="Full Import"
                            icon="download"
                            primary
                            danger
                            v-if="canImport && !state.slugEditable"
                            @onPress="importFullModelClick" />

                        <Button
                            class="float-right"
                            title="Import"
                            icon="download"
                            primary
                            :disabled="!canImport"
                            v-if="canImport"
                            @onPress="importModelClick" />
                    </template>
        		</Card>
    	    </div>
		</div>
        <ModalDialog title="Error" :visible="!!errorMsg" @hidden="clearError()">
            <p class="break">{{errorMsg}}</p>
        </ModalDialog>
	</AuthLayout>
</template>

<script>
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { Getter, Action } from 'vuex-class';
	import AuthLayout from '../Auth';
    import Button from '../../components/Button';
	import Card from '../../components/Card';
    import FormInput from '../../components/FormInput';
    import ModalDialog from '../../components/ModalDialog';

    const DEFAULT_STATE = {
        importId: '',
        title: '',
        slug: '',
        password: '',
        token: '',
        passwordRequired: false,
        valid: false,
        slugEditable: true,
    };

    @Component({
        components: {
            AuthLayout,
            ModalDialog,
            FormInput,
            Card,
            Button,
        },
        filters: {
            timeRemaining(value) {
                if(typeof value !== 'number') return 'Pending...';
                if(value > 60*60) return Math.ceil(value/60/60)+ 'h';
                if(value > 60) return Math.ceil(value/60)+ ' min';
                return value + 's';
            }
        }
    })
    export default class SidebarLayout extends Vue {
        @Getter('api/importer/list') items;
        @Action('api/importer/refresh') refresh;
        @Action('api/importer/importModel') importModel;
        @Action('api/importer/prefetchModel') prefetchModel;
        @Action('api/importer/restoreScans') restoreScans;

        busy = false;

        state = {
            ...DEFAULT_STATE,
        };

        errorMsg = null;
        clearError() {
            this.errorMsg = null;
        }
        showError(e) {
            console.error(e);
            this.errorMsg = e.message;
        }

        loadImportId(modelId) {
            this.state.importId = modelId;
            this.onModelIdChange();
        }
        
        @Prop
        get canImport() {
            return this.state.valid && this.state.importId.length > 5 && this.state.slug.match(/^\w*$/) && this.state.title;
        }

        triggerPrefetch() {
            if(!this.state.importId.match(/^\w+$/)) {
                return;
            }
            this.prefetchModel({
                modelId: this.state.importId,
                password: this.state.password,
            }).then(({title, slug, passwordRequired, valid, token, slugEditable}) => {
                this.state.valid = valid || false;
                this.state.title = title || '';
                this.state.slug = slug || '';
                this.state.token = token;
                this.state.slugEditable = slugEditable;
                this.state.passwordRequired = passwordRequired || false;
                if(slugEditable) {
                    this.onTitleChange();
                }
            }).catch(e => this.showError(e));;
        }

        onModelIdChange() {
            this.state = {
                ...DEFAULT_STATE,
                importId: this.state.importId,
            };
            if (this.state.importId.length > 5 && !this.state.title) {
                this.triggerPrefetch();
            }
        }

        onLogin() {
            this.triggerPrefetch();
        }

        onTitleChange() {
            if(!this.state.slugEditable) {
                return;
            }
            this.state.slug = this.state.title
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .replace(/\W/g, '_')
                .replace(/_+/g, '_')
                .replace(/^_/, '')
                .replace(/_$/, '')
                .toLowerCase();
        }
        
        async beforeMount() {
            this.refresh();
        }

        async _importModel(full = false) {
            console.log('import', this.state.importId)
            this.importModel({
                modelId: this.state.importId,
                title: this.state.title,
                slug: this.state.slug,
                password: this.state.password,
                includeFiles: full,
                includePlayer: full,
            }).catch(e => this.showError(e));
            this.state = {
                ...DEFAULT_STATE,
            };
        }
        
        async importModelClick() {
            return this._importModel(false);
        }
                
        async importFullModelClick() {
            return this._importModel(true);
        }

        async restore() {
            this.busy = true;
            try {
                const {restored, failed} = await this.restoreScans();
                const msgs = [];
                if (restored.length) {
                    msgs.push(`Restored ${restored.length} scans: ${restored.join(', ')}.`);
                }
                if(failed.length) {
                   msgs.push(`Failed to restore ${failed.length} scans: ${failed.join(', ')}.`);
                   msgs.push('The scans failed to restore because their original metadata could not be found.');
                   msgs.push('Please make sure these scans have their original names.');
                }
                if(!msgs.length) {
                    msgs.push('No scans found that are available for restoration!');
                }
                this.showError({message: msgs.join('\n')});
            } finally {
                this.busy = false;
            }
        }
    }
</script>

<style type="text/css">
.break {
    white-space: pre-wrap;
}
</style>