import * as Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';

import { api } from './api/store';

Vue.use(Vuex);

const getPlugins = (modules) => {
	return (modules ? Object.values(modules) : [])
		.map(module => (module.plugins || []).concat(...getPlugins(module.modules)));
};

const modules = {
	api,
};

export const store = new Vuex.Store({
	modules,
	plugins: [
		createPersistedState({
			paths: [
				'api.sessionId',
			],
		}),
	].concat(...getPlugins(modules)),
	strict: true,
});
