import { AssertLogin } from '../../../../util/decorators';
import AdminAPI from '../../../../api';

export const headsets = {
	namespaced: true,

	state: {
    	headsets: []
	},

	getters: {
    	list(state) {
        	return state.headsets;
    	},
	},

	mutations: {
    	_setHeadsets(state, headsets) {
        	state.headsets = headsets;
    	},
		_clearAll(state){
			state.headsets = [];
		},
	},

	actions: {
        @AssertLogin()
    	async refresh({commit}) {
        	const headsets = await AdminAPI.fire('vr/headsets/getAll');
        	
			commit('_setHeadsets', headsets);
    	},
        @AssertLogin()
		async getHeadset({ commit }, id) {
    		return await AdminAPI.fire('vr/headsets/getHeadset', id);
		},
        @AssertLogin()
		async editHeadset({ commit }, data) {
    		return await AdminAPI.fire('vr/headsets/editHeadset', data);
		},
        @AssertLogin()
		async deleteHeadset({ commit, dispatch }, id) {
    		const res = await AdminAPI.fire('vr/headsets/deleteHeadset', id);
    		dispatch('refresh');
    		return res;
		},
		    @AssertLogin()
		async publishData({ commit, dispatch }, id) {
    		const res = await AdminAPI.fire('vr/headsets/publishData', id);
    		return res;
		},
	},
};
