<template>
    <a class="nav-item nav-link" v-bind:class="{ active: isActive }" @click.prevent="onClick" href="#">
        <slot/>
    </a>
</template>

<script>
	import { Component, Vue, Prop } from 'vue-property-decorator';

	@Component({
		components: {},
	})
	export default class MenuItem extends Vue {
    	@Prop(String) title;
    	@Prop(String) hotkey;
    	@Prop(String) path;
    	
    	@Prop
    	get isActive() {
        	if(this.path == '/') return window.location.hash == '#/'
        	return window.location.hash.startsWith('#'+this.path);
    	}
    	
    	onClick() {
        	this.$router.push(this.path);
    	}
	}
</script>