<template>
    <SidebarLayout>
        <Card title="VR Headsets">
            <Button slot="header-widget" title="Add" icon="glasses" @onPress="addHeadset" small/>
            <TableListView :columns="columns" :rows="headsets">
                <div slot="actions" slot-scope="{row}" class="text-right model-actions">
                    <Button icon="edit" small @onPress="editHeadset(row)"/>
                    <Button icon="sync" small warning @onPress="publishData(row.id)"/>
                    <Button icon="trash" small danger @onPress="deleteHeadset(row)"/>
                </div>
            </TableListView>
        </Card>
    </SidebarLayout>
</template>

<script>
	import {Component, Vue, Prop} from 'vue-property-decorator';
	import SidebarLayout from '../../layouts/Sidebar';
	import Card from '../../components/Card';
	import Button from '../../components/Button';
	import Avatar from '../../components/Avatar';
	import TableListView from '../../components/TableListView';
	import {Getter, Action, Mutation} from 'vuex-class';

	const COLUMNS = [
        {
            name: 'Name',
            id: 'name'
        },
        {
            name: 'Owner',
            id: 'owner'
        },
        {
            name: 'Scans',
            id: 'scans',
            filter: (val) => (val||[]).map(val => val.modelName).join(', ') || 'None'
        },
        {
            name: 'Enabled',
            id: 'enabled',
            filter: (val) => val ? 'Yes' : 'No'
        },
	];


	@Component({
		components: {
			SidebarLayout,
			Card,
			Button,
			TableListView,
			Avatar,
		},
	})
	export default class ViewVRHeadsets extends Vue {
    	@Getter('api/vr/headsets/list') headsets;
    	@Action('api/vr/headsets/refresh') refreshHeadsets;
    	@Action('api/vr/headsets/deleteHeadset') _deleteHeadset;
    	@Action('api/vr/headsets/publishData') publishData;
        columns = COLUMNS;

        beforeMount() {
            this.refreshHeadsets();
        }

        addHeadset() {
            this.$router.push({name: 'addVRHeadset'});
        }

        editHeadset(vr) {
            this.$router.push({name: 'editVRHeadset', params: {id: vr.id}});
        }

        deleteHeadset(vr) {
            if(confirm('Are you sure you want to delete this VR headset?') === true) {
                this._deleteHeadset(vr.id).catch(err => alert(err.message));
            }
        }
	}
</script>

<style lang="css">
    .model-actions {
        width: 125px;
    }
</style>
