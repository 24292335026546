<template>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
			<div class="modal-header">
			    <h5 v-if="title" class="modal-title">{{title}}</h5>
			</div>
			<div class="modal-body">
				<slot/>
			</div>
		</div>
	</div>
</template>

<script>
	import { Component, Vue, Prop } from 'vue-property-decorator';

	@Component({
		components: {
		},
	})
	export default class Dialog extends Vue {
		@Prop(String) title;
	}
</script>