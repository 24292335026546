export function AssertLogin(shouldBe = true) {
	return function decorator(target, propertyKey, descriptor) {
		const originalMethod = descriptor.value;

		descriptor.value = function (context, ...args) {
			if (!context.rootGetters) throw new Error('Error, this decorator can only be used on store actions!');

			if (context.rootGetters['api/isLoggedIn'] !== shouldBe) {
				return Promise.reject(new Error(`${shouldBe ? 'not_' : ''}logged_in`));
			}

			return originalMethod.apply(this, [context, ...args]);
		};

		return descriptor;
	};
}

export function RequireUser() {
	return function decorator(target, propertyKey, descriptor) {
		const originalMethod = descriptor.value;

		const result = {
			@AssertLogin()
			decoratedFn(context, ...args) {
				if (!context.rootGetters) throw new Error('Error, this decorator can only be used on store actions!');

				if (context.rootGetters['api/user']) {
					return originalMethod.apply(this, [context, ...args]);
				}

				return context.dispatch('api/getUser', null, { root: true })
					.then(() => originalMethod.apply(this, [context, ...args]));
			}
		};

		descriptor.value = result.decoratedFn;
		return descriptor;
	}
}