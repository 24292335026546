import { AssertLogin } from '../../../../util/decorators';
import AdminAPI from '../../../../api';

export const videos = {
	namespaced: true,

	state: {
	},

	getters: {
	},

	mutations: {
	},

	actions: {
    @AssertLogin()
		async editVideo({ commit }, {video, scanId}) {
    		return await AdminAPI.fire('scans/videos/editVideo', {video, scanId});
		},
    @AssertLogin()
		async deleteVideo({ commit }, videoId) {
    		return await AdminAPI.fire('scans/videos/deleteVideo', videoId);
		},
		@AssertLogin()
		async publishVideos({commit}, scanId) {
			return await AdminAPI.fire('scans/videos/publishVideos', scanId);
		},
		@AssertLogin()
		async createUploadUrl({commit}, videoId) {
    	return await AdminAPI.fire('scans/videos/getVideoUploadUrl', videoId);
		}
	},
};
