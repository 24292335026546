<template>
    <SidebarLayout>
        <Card title="Users">
            <Button slot="header-widget" title="Add" icon="user-plus" @onPress="addUser" small/>
            <TableListView :columns="columns" :rows="users">
                <Avatar slot="icon" slot-scope="{row}" :src="row.avatar" width="40" height="40" />
                <div slot="actions" slot-scope="{row}" class="text-right">
                    <Button icon="edit" small @onPress="editUser(row)"/>&nbsp;
                    <Button icon="trash" small danger @onPress="deleteUser(row)"/>
                </div>
            </TableListView>
        </Card>
    </SidebarLayout>
</template>

<script>
	import {Component, Vue, Prop} from 'vue-property-decorator';
	import SidebarLayout from '../../layouts/Sidebar';
	import Card from '../../components/Card';
	import Button from '../../components/Button';
	import Avatar from '../../components/Avatar';
	import TableListView from '../../components/TableListView';
	import {Getter, Action, Mutation} from 'vuex-class';
	
	const COLUMNS = [
        {
            name: 'Name',
            id: 'name'
        },
        {
            name: 'E-Mail',
            id: 'email'
        },
        {
            name: 'Role',
            id: 'role', 
            filter: (val) => 'Admin'
        },
	];
	

	@Component({
		components: {
			SidebarLayout,
			Card,
			Button,
			TableListView,
			Avatar,
		},
	})
	export default class ViewUsers extends Vue {
    	@Getter('api/users/list') users;
    	@Action('api/users/refresh') refreshUsers;
    	@Action('api/users/deleteUser') _deleteUser;
        columns = COLUMNS;
        
        beforeMount() {
            this.refreshUsers();
        }
        
        addUser() {
            this.$router.push({name: 'addUser'});
        }

        editUser(user) {
            this.$router.push({name: 'editUser', params: {id: user.id}});
        }

        deleteUser(user) {
            if(confirm('Are you sure you want to delete this user?') === true) {
                this._deleteUser(user.id).catch(err => alert(err.message));
            }
        }
	}
</script>

<style lang="scss">

</style>