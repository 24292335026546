<template>
    <table class="table">
        <thead>
            <tr>
                <th v-if="$scopedSlots.icon" style="width: 40px;"><slot name="icon-header"/></th>
                <th v-for="(col, j) in columns" v-bind:class="{'d-none': j, 'd-md-table-cell': j}">{{col.name}}</th>
                <th v-if="$scopedSlots.actions" class="text-right"><slot name="actions-header"/></th>
            </tr>
        </thead>
        <tr v-for="(row, i) in rows" class="align-middle">
            <td class="align-middle" v-if="$scopedSlots.icon"><slot name="icon" :row="row"/></td>
            <td class="align-middle" v-for="(col, j) in columns" v-bind:class="{'d-none': j, 'd-md-table-cell': j}">{{ (col.filter ? col.filter(row[col.id]) : row[col.id]) }}</td>
            <td class="align-middle text-right" v-if="$scopedSlots.actions">
                <Button :disabled="i === 0" primary small icon="arrow-up" @onPress="moveUp(i)" />
                <Button :disabled="i === rows.length - 1" primary small icon="arrow-down" @onPress="moveDown(i)" />
                <slot name="actions" :row="row"/>
            </td>
        </tr>
    </table>
</template>

<script>
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

    import Button from '../Button';

	@Component({
		components: {
            Button,
		},
	})
	export default class DraggableTableListView extends Vue {
    	@Prop(Array) columns;
    	@Prop(Array) value;

        rows = [];

        @Watch('value')
        onValueChange(value) {
            this.rows = value;
        }

        moveDown(i) {
            this.rows[i] = this.rows.splice(i + 1, 1, this.rows[i])[0];

            this.$emit('input', this.rows);
        }

        moveUp(i) {
            this.rows[i] = this.rows.splice(i - 1, 1, this.rows[i])[0];

            this.$emit('input', this.rows);
        }
	}
</script>
