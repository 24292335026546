import { headsets } from './headsets/store';

export const vr = {
	namespaced: true,

	state: {
	},

	getters: {
	},

	mutations: {
	},

	modules: {
		headsets,
	},

	actions: {
	},
};
