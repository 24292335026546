import { AssertLogin } from '../../../../util/decorators';
import AdminAPI from '../../../../api';

export const tags = {
	namespaced: true,

	state: {
	},

	getters: {
	},

	mutations: {
	},

	actions: {
    @AssertLogin()
		async editTag({ commit }, data) {
    		return await AdminAPI.fire('scans/tags/editTag', data);
		},
    @AssertLogin()
		async addTag({ commit }, data) {
    		return await AdminAPI.fire('scans/tags/addTag', data);
		},
    @AssertLogin()
		async deleteTag({ commit }, tagId) {
    		return await AdminAPI.fire('scans/tags/deleteTag', tagId);
		},
		@AssertLogin()
		async publishTags({commit}, scanId) {
			return await AdminAPI.fire('scans/tags/publishTags', scanId);
		},
		@AssertLogin()
		async copyTags({commit}, {destination, source}) {
			return await AdminAPI.fire('scans/tags/copyTags', {destination, source});
		},
	},
};
