<template>
    <SidebarLayout>
        <Card title="User">
            <Button slot="header-widget" title="Back" icon="caret-left" @onPress="back" small/>

            <Avatar v-if="user && user.avatar" :src="user.avatar" width="80" height="80" class="mx-auto d-block mb-4"/>

            <Form v-if="user" @submit="saveUser" :error="error">
                <FormInput
                    type="email"
                    autocomplete="new-email"
                    prepend="E-mail"
                    v-model="user.email"
                />
                <FormInput
                    type="text"
                    autocomplete="given-name"
                    prepend="First Name"
                    v-model="user.firstname"
                />
                <FormInput
                    type="text"
                    autocomplete="family-name"
                    prepend="Last Name"
                    v-model="user.lastname"
                />
                <FormInput
                    type="password"
                    autocomplete="new-password"
                    prepend="New Password"
                    v-model="user.password"
                />
                <FormInput 
                    type="password"
                    autocomplete="new-password"
                    prepend="Repeat"
                    v-model="user.password2"
                    v-if="user.password"
                />
            </Form>

            <Button class="float-right" slot="footer" title="Save" icon="save" primary @onPress="saveUser"/>
        </Card>
    </SidebarLayout>
</template>

<script>
	import {Component, Vue, Prop} from 'vue-property-decorator';
	import SidebarLayout from '../../layouts/Sidebar';
	import Card from '../../components/Card';
	import Button from '../../components/Button';
	import Form from '../../components/Form';
	import FormInput from '../../components/FormInput';
	import Avatar from '../../components/Avatar';
	import {Getter, Action, Mutation} from 'vuex-class';


	@Component({
		components: {
			SidebarLayout,
			Card,
			Button,
			FormInput,
			Form,
			Avatar
		},
	})
	export default class ViewEditUser extends Vue {
    	@Action('api/users/editUser') editUser;
    	@Action('api/users/getUser') getUser;

        user = null;
        error = '';

        async beforeMount() {
            if(this.$route.params.id) {
                this.user = await this.getUser(this.$route.params.id);
            } else {
                this.user = {};
            }
        }

        back() {
            this.$router.back();
        }

        async saveUser() {
            this.error = '';
            if(this.user.password && this.user.password !== this.user.password2) {
                this.error = "Passwords do not match!";
                return;
            }
            try {
                await this.editUser(this.user);
                this.$router.push({name:'users'});
            } catch(err) {
                this.error = err.message;
            }

        }
	}
</script>
