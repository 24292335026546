<template>
    <DefaultLayout>
        <Dialog class="Center" title="Log-in" style="min-height: 70vh;">
            
            <div v-if="isLoggedIn" class="Center">
            	<p>You are already logged in. Please continue or log out.</p>
				<Button @onPress="_continue" title="Continue"/>
            </div>
            
            <div v-if="!isLoggedIn" class="Center">
            
                <Form :error="state.error" hint="Please log-in to continue." @submit="_login">
                    <FormInput 
                        type="email"
                        ref="email"
                        placeholder="E-mail"
                        v-model="_username"
                        id="username"
                    />
                    <FormInput
                        type="password"
                        autocomplete="current-password"
                        ref="password"
                        placeholder="Password"
                        v-model="_password"
                        id="password"
                    />

                    <input type="submit" class="btn btn-primary float-right" value="Login"/>
				</Form>
            </div>
            
        </Dialog>
    </DefaultLayout>
</template>

<script>
	import {Component, Vue, Prop, Provide} from 'vue-property-decorator';
	import DefaultLayout from '../../layouts/Default';
	import Dialog from '../../components/Dialog';
	import Form from '../../components/Form';
	import FormInput from '../../components/FormInput';
	import Button from '../../components/Button';
	import {Getter, Action, Mutation} from 'vuex-class';

	@Component({
		components: {
			DefaultLayout,
			Dialog,
			Button,
			Form,
			FormInput,
		},
	})
	export default class ViewLogin extends Vue {
		@Action('api/authenticate') login;
		@Getter('api/isLoggedIn') isLoggedIn;
        @Prop({ type: String}) redirectTo;
        constructor() {
            super();
            this.state = {error: ''};
        }
        
        beforeMount() {
            this._username = '';
            this._password = '';
        }

		async _login() {
    		if(!this._username) return this.$refs.email.focus();
    		if(!this._password) return this.$refs.password.focus();
    		try {
                await this.login({
            		username: this._username,
            		password: this._password,
        		});
        		this._continue();
    		} catch(err) {
        		console.log('auth error:', err);
        		this.state.error = err.message;
    		}
		}

		_continue() {
            if (this.redirectTo) {
                this.$router.push(this.redirectTo);
            } else {
                this.$router.push('/');
            }
        }
	}
</script>

<style lang="scss">

</style>