<template>
	<div>
		<header>
			<TopBar></TopBar>
		</header>
		<div id="main_container" class="container">
			<slot v-if="_connected && !_connecting"/>
			<Dialog title="Error" v-else-if="!_connecting">
			    Not connected
			</Dialog>
		</div>
	</div>
</template>

<script>
	import { Component, Vue } from 'vue-property-decorator';
	import { Getter, Action, Mutation } from 'vuex-class';
	import TopBar from '../../components/TopBar';
	import Dialog from '../../components/Dialog';

	@Component({
		components: {
			TopBar,
			Dialog,
		},
	})
	export default class LayoutDefault extends Vue {
        @Getter('api/connected') _connected;
        @Getter('api/connecting') _connecting;
	}


</script>

<style type="css">
#main_container {
    min-height: 70vh;
	margin-top: 70px;
}
</style>