import { AssertLogin } from '../../../../util/decorators';
import AdminAPI from '../../../../api';

export const locations = {
	namespaced: true,

	state: {
	},

	getters: {
	},

	mutations: {
	},

	actions: {
        @AssertLogin()
		async editLocation({ commit }, data) {
    		return await AdminAPI.fire('scans/locations/editLocation', data);
		},
	},
};
