<template>
		<nav class="navbar navbar-expand-md navbar-dark bg-dark justify-content-between">
		    <img class="navbar-brand" src="/logo.png" alt="logo" height="45" width="91"/>
            <div class="navbar-collapse" v-bind:class="{ collapse: !isExpanded }">
                <div class="navbar-nav" v-if="isLoggedIn">
                    <MenuItem path="/">Dashboard</MenuItem>
                    <MenuItem path="/users">Users</MenuItem>
                    <MenuItem path="/vr/headsets">VR Headsets</MenuItem>
                    <!--<MenuItem path="/tags">Tags</MenuItem>
                    <MenuItem path="/audio">Audio</MenuItem>
                    <MenuItem path="/settings">Settings</MenuItem>-->
                </div>
            </div>
            <div class="fixed-top mt-2 mr-2" style="left:47%">
                <button class="navbar-toggler mr-3" type="button" @click="toggleMenu">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="dropdown d-inline-block float-right" v-if="user">
                    <span @click="toggleUserMenu" class="btn text-light d-none d-md-inline">{{user.firstname}} {{user.lastname}}</span>
                    <Avatar @onPress="toggleUserMenu" :src="user.avatar" class="btn"/>
                    <div class="dropdown-menu dropdown-menu-right" v-bind:class="{ 'dropdown-menu-open': isUserExpanded }">
                        <h6 class="dropdown-header d-md-none">{{user.firstname}} {{user.lastname}}</h6>
                        <div class="dropdown-divider d-md-none"></div>
                        <a class="dropdown-item" href="#" @click.prevent="openProfile"><i class="fas fa-user mr-1"></i> Profile</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item bg-danger active" href="#" @click.prevent="logout"><i class="fas fa-sign-out-alt mr-1"></i>Logout</a>
                    </div>
                </div>
            </div>
		</nav>
</template>

<script>
	import { Component, Vue } from 'vue-property-decorator';
	import { Getter, Action } from 'vuex-class';
	import MenuItem from './MenuItem';
	import Button from '../Button';
	import Avatar from '../Avatar';

	@Component({
		components: {
			MenuItem,
			Button,
			Avatar,
		},
	})
	export default class TopBar extends Vue {
		@Getter('api/isLoggedIn') isLoggedIn;
		@Getter('api/user') user;
		@Action('api/logout') logout;
		isExpanded = false;
		isUserExpanded = false;
		
		toggleMenu() {
    		this.isExpanded = !this.isExpanded;
		}
		
		toggleUserMenu() {
    		this.isUserExpanded = !this.isUserExpanded;
    		
		}
		
		openProfile() {
            this.$router.push({name: 'editUser', params: {id: this.user.id}});
		}
	}
</script>

<style type="text/css">
    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 50;
    }
    .dropdown-menu-right {
        right: 0;
        left: auto;
    }
    .dropdown-menu-open {
        display: block;
    }
</style>