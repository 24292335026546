<template>
    <div>
        <input type="text" class="form-control" placeholder="Search" v-model="searchQuery" />

        <TableListView :columns="columns" :rows="filteredSource">
            <span v-if="$scopedSlots.icon" slot="icon" slot-scope="{row}">
                <slot name="icon" :row="row" />
            </span>
            <span slot="actions" slot-scope="{row}">
                <Button primary small icon="plus" @onPress="addItem(row) "/>
            </span>
        </TableListView>

        <p class="text-muted" v-if="filteredSource.length === 0 && searchQuery.trim().length ">
            No search results.
        </p>

        <p class="text-muted" v-else-if="filteredSource.length === 0">
            No items were found.
        </p>

        <hr>

        <DraggableTableListView :columns="columns" v-model="rows" @input="input" v-if="value.length">
            <span v-if="$scopedSlots.icon" slot="icon" slot-scope="{row}">
                <slot name="icon" :row="row" />
            </span>
            <span slot="actions" slot-scope="{row}">
                <Button danger small icon="minus" @onPress="removeItem(row) "/>
            </span>
        </DraggableTableListView>
    </div>
</template>

<script>
import {Component, Vue, Prop, Provide} from 'vue-property-decorator';

import DraggableTableListView from '../DraggableTableListView';
import TableListView from '../TableListView';
import Button from '../Button';

@Component({
    components: {
        TableListView,
        DraggableTableListView,
        Button,
    },
})
export default class FormInputDualListSelector extends Vue {
    @Prop({ type: Array }) source;
    @Prop({ type: String }) searchField;
    @Prop({ type: Array }) columns;
    @Prop({ type: Array }) value;

    searchQuery = '';
    rows = [];

    addItem(row) {
        this.rows.push(row);

        this.$emit('input', this.rows);
    }

    removeItem(row) {
        this.rows = this.rows.filter((r) => r !== row);

        this.$emit('input', this.rows);
    }

    get filteredSource() {
        const query = this.searchQuery.trim().toLowerCase();
        return this.source.filter((item) => {
            if (this.value.some((selected) => selected.id === item.id)) {
                return false;
            }

            return item[this.searchField].substr(0, query.length).toLowerCase() === query
        });
    }

    mounted() {
        this.rows = [...this.value];
    }

    input() {
        this.$emit('input', this.rows);
    }
}
</script>
