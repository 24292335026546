<template>
    <form action="#" v-on:submit.prevent="_submit">
        <p v-if="hint">{{hint}}</p>
        <div class="alert alert-danger" role="alert" v-if="error">
            {{error}}
        </div>
      <slot/>
	</form>
</template>

<script>
	import {Component, Vue, Prop, Provide} from 'vue-property-decorator';

	@Component({
		components: {
		},
	})
	export default class Form extends Vue {
        @Prop({ type: String}) error;
        @Prop({ type: String}) hint;
        
		async _submit() {
    		this.$emit('submit');
		}
	}
</script>