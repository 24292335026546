import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard';
import Users from './views/Users';
import EditUser from './views/EditUser';
import Login from './views/Login';
import VRHeadsets from './views/VRHeadsets';
import EditVRHeadset from './views/EditVRHeadset';
import ShowScan from './views/ShowScan';

Vue.use(Router);

export default new Router({
	routes: [

		{
			path: '/',
			name: 'dashboard',
			component: Dashboard,
		},

		{
			path: '/users',
			name: 'users',
			component: Users,
		},
		{
			path: '/users/add',
			name: 'addUser',
			component: EditUser,
		},
		{
			path: '/users/:id/edit',
			name: 'editUser',
			component: EditUser,
		},
		{
			path: '/users/:id/delete',
			name: 'deleteUser',
			component: EditUser,
		},

		{
			path: '/vr/headsets',
			name: 'VRHeadsets',
			component: VRHeadsets,
		},
		{
			path: '/vr/headsets/add',
			name: 'addVRHeadset',
			component: EditVRHeadset,
		},
		{
			path: '/vr/headsets/:id/edit',
			name: 'editVRHeadset',
			component: EditVRHeadset,
		},
		{
			path: '/vr/headsets/:id/delete',
			name: 'deleteVRHeadset',
			component: EditVRHeadset,
		},
		{
			path: '/scans/:id',
			name: 'showScan',
			component: ShowScan
		},

		{
			path: '/login',
			name: 'login',
			component: Login,
			props: true
		},
	],
});
