<template>
    <SidebarLayout>
        <Card title="3D Scans">
            <TableListView :columns="columns" :rows="scans">
                <Avatar slot="icon" slot-scope="{row}" :src="row.thumb" width="40" height="40" />
                <span slot="actions" slot-scope="{row}" class="text-right model-actions">
                    <span v-if="row.outdatedFiles" style="color: red"><i class="fas fa-exclamation-triangle"></i> Outdated </span>
                    <span v-if="!row.outdatedFiles && row.outdatedPlayer" style="color: orange"><i class="fas fa-exclamation-triangle"></i> Outdated </span>
                    <Button icon="list" primary small @onPress="openScan(row)" />
                    <Button icon="external-link-alt" primary small @onPress="openModel(row.link)"/>
                </span>
            </TableListView>
        </Card>
    </SidebarLayout>
</template>

<script>
	import {Component, Vue, Prop} from 'vue-property-decorator';
	import SidebarLayout from '../../layouts/Sidebar';
	import TableListView from '../../components/TableListView';
	import Card from '../../components/Card';
	import Avatar from '../../components/Avatar';
	import Button from '../../components/Button';
	import {Getter, Action, Mutation} from 'vuex-class';

    const COLUMNS = [
        {
            name: 'Name',
            id: 'modelName'
        },
	];

	@Component({
		components: {
			SidebarLayout,
			TableListView,
			Card,
			Avatar,
			Button
		},
	})
	export default class ViewDashboard extends Vue {
    	@Getter('api/scans/list') scans;
    	@Action('api/scans/refresh') refreshScans;
        columns = COLUMNS;

        beforeMount() {
            this.refreshScans();
        }

        openModel(link) {
            window.open(link, '_blank');
        }

        openScan(scan) {
            this.$router.push({name: 'showScan', params: {id: scan.id}});
        }
	}
</script>

<style type="text/css">
    .model-actions {
        width: 300px;
    }
    .model-link {
        width: 250px;
    }
</style>
